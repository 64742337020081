/* eslint-disable max-lines */
/* eslint-disable max-len */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, {useState, useEffect, useRef} from 'react';
import {object, func, bool, array, oneOfType, string} from 'prop-types';
import {useParams, useHistory} from 'react-router-dom';
import {SideMenu, UserModal, ExtraFieldsComponent, ProfilePicture} from '../../components';
import {ReactComponent as TeamLeaderIcon} from '../../assets/icons/team-leader-white-icon.svg';
import {ReactComponent as EditIcon} from '../../assets/icons/edit-button-icon-grey.svg';
import {ReactComponent as ExportPDFIcon} from '../../assets/icons/export-pdf-icon.svg';
import {TabView, TabPanel} from 'primereact/tabview';
import {formatDate, formatDateRelevantExpUI, userCan, getUserImageUrl} from '../../utils/user';
import {map, filter, isEmpty} from 'lodash';
import {InputText} from 'primereact/inputtext';
import {ExportButton, EditButton, GoBackButton, DeleteRestoreButton, ExportPDFButton, SaveButton} from '../../components/StyledButtons/StyledButtons';
import {exportToExcel} from './exportToExcel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ReactComponent as ExportIcon} from '../../assets/icons/export-icon.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreIcon from '@mui/icons-material/Restore';
import {CAPABILITIES, ENV_URL} from '../../constant';
import {showSuccessfulToast, showUnsuccessfulToast} from '../../utils/utils';
import {Toast} from 'primereact/toast';
import './ProfilePage.scss';
import {savePDF} from '@progress/kendo-react-pdf';
import CVDocument from '../../components/CVDocument';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import {ROUTE_PATH} from 'constant';
import {setSearchInputValue} from '../../store/action/setSearchInputValue';

const ProfilePage = props => {
  const [user, setUser] = useState(null);
  const [userTeamLead, setUserTeamLead] = useState('');
  const [userDialog, setUserDialog] = useState(false);
  const [isLoggedUserProfile, setIsLoggedUserProfile] = useState(false);
  const [isLoggedUserTeamLead, setIsLoggedUserTeamLead] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [displayExtraFields, setDisplayExtraFields] = useState(false);
  const [canTeamLeadAction, setCanTeamLeadAction] = useState(false);
  const [canExtraDetailsAction, setCanExtraDetailsAction] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRestoreModal, setOpenRestoreModal] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [file, setFile] = useState(null);
  const [fileSelected, setFileSelected] = useState(false);
  const [hasFile, setHasFile] = useState(false);

  const history = useHistory();
  const toast = useRef(null);

  let {id} = useParams();

  const getUserProfile = () => {
    const currentUser = filter(Object.values(props.users), userProfile => userProfile.id === id);

    return currentUser[0];
  };

  const formatName = name => name.toLowerCase().replace(/ /g, '.');

  useEffect(() => {
    if (isEmpty(props.allDaysLeft)) {
      props.getAllDaysLeft();
    }
    if (isEmpty(props.skills)) {
      props.getSkills();
    }
    if (isEmpty(props.roles)) {
      props.getRoles();
    }
    if (isEmpty(props.teams)) {
      props.getTeams();
    }
    if (isEmpty(props.jobs)) {
      props.getJobs();
    }
    if (isEmpty(props.users)) {
      props.getAllUsers();
    }
    if (isEmpty(props.teamLeads)) {
      props.getTeamleads();
    }
  }, []);

  useEffect(() => {
    setUser(getUserProfile());
  }, [props.users, id]);

  useEffect(() => {
    setIsLoggedUserProfile(false);
    if (user?.id === props.getLoggedUser.id) {
      setIsLoggedUserProfile(true);
    }
    const isLoggedUserTeamLeadTemp = Object.values(props.roles).some(role => role.id === props.getLoggedUser.role && role.name === 'Team lead');

    setIsLoggedUserTeamLead(isLoggedUserTeamLeadTemp);
  }, [props.getLoggedUser, user]);

  useEffect(() => {
    if (user) {
      setUserTeamLead(map(user.teamLead, teamLead => teamLead.name));

      const isUserInLoggedUserTeamTemp = Object.values(user.team).some(team => props.getLoggedUser.team.includes(team.id));

      const canEditBenefits = userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_BITSTONE_INFORMATION.key);
      const canEditRelevantExp = userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_RELEVANT_EXPERIENCE.key);
      const canEditProjectsExp = userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_PROJECTS_EXPERIENCE.key);
      const canEditPersonalInfo = userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_PERSONAL_INFO.key);


      if (isLoggedUserTeamLead && isUserInLoggedUserTeamTemp) {
        setCanTeamLeadAction(true);
      }
      if (canEditBenefits || canEditRelevantExp || canEditPersonalInfo || canEditProjectsExp) {
        setCanExtraDetailsAction(true);
      }
      setImageUrl(getUserImageUrl(user, ENV_URL));

      const apiUrl = ENV_URL.API_ENDPOINT;

      const fileName = formatName(user.name);

      fetch(`${apiUrl}/user/exists/${fileName}`, {
        headers : {
          Authorization : `Bearer ${props.accessToken}`
        }
      })
        .then(response => response.json())
        .then(data => {
          setHasFile(data.status);
        })
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error('Fetch error:', error);
        });
    }
  }, [user, props.capabilities]);

  useEffect(() => {
    const shouldFetch = [props.deleteUserSuccessful, props.editUserSuccessful, props.restoreUserSuccessful].some(Boolean);

    if (shouldFetch) {
      props.getAllUsers();
      props.getProjectsTitle();
      setUser(getUserProfile());
    }
  }, [props.deleteUserSuccessful, props.editUserSuccessful, props.restoreUserSuccessful]);

  const deleteFile = fileName => {
    const apiUrl = ENV_URL.API_ENDPOINT;

    fetch(`${apiUrl}/user/delete-file/${fileName}.ovpn`, {
      method  : 'DELETE',
      headers : {
        Authorization : `Bearer ${props.accessToken}`
      }
    })
      .then(response => {
        if (response.ok) {
          props.getAllUsers();
          setFile(null);
          setHasFile(false);
          showSuccessfulToast(toast, 'File deleted successfully');
        } else {
          throw new Error('Error deleting file');
        }
      });
  };

  const downloadFile = fileName => {
    const apiUrl = ENV_URL.API_ENDPOINT;

    fetch(`${apiUrl}/user/download-ovpn/${fileName}`, {
      headers : {
        Authorization : `Bearer ${props.accessToken}`
      }

    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');

        anchor.href = url;
        anchor.download = `${fileName}.ovpn`;
        document.body.appendChild(anchor);
        anchor.click();

        window.URL.revokeObjectURL(url);
        document.body.removeChild(anchor);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('There was an error downloading the file:', error);
      });
  };


  const editUser = () => {
    setUserDialog(true);
  };

  const openExtraFields = () => {
    setDisplayExtraFields(true);
  };

  const userProps = {
    isLoggedUserProfile,
    setUserInfo : setUser,
    userInfo    : user,
    userTeamLead,
    setUserTeamLead
  };

  const deleteUser = async () => {
    await props.deleteUser({id : user.id});
    showSuccessfulToast(toast, 'User archived');
    setOpenDeleteModal(false);
  };

  const restoreUser = async () => {
    await props.restoreUser({id : user.id});
    showSuccessfulToast(toast, 'User restored');
    setOpenRestoreModal(false);
  };

  const [showPreview, setShowPreview] = useState(false);

  const downloadPDF = () => {
    const element = document.querySelector('#pdf-content');

    savePDF(element, {
      paperSize : 'A4',
      fileName  : `${user.name}_CV.pdf`,
      scale     : 0.75
    }, () => {
      setShowPreview(false);
    });
  };

  const formatExperienceDate = date => {
    if (date === 'Present') {
      return date;
    }

    return new Date(date);
  };

  const shouldDisplayLine = (endDate, nextStartDate) => {
    if (endDate === 'Present') {
      return true;
    }
    if (nextStartDate === 'Present') {
      return false;
    }

    return endDate.getFullYear() > nextStartDate.getFullYear();
  };

  const handleOnDeleteUser = () => {
    setOpenDeleteModal(true);
  };
  const handleOnRestoreUser = () => {
    setOpenRestoreModal(true);
  };

  const cvDialogFooter = (
    <>
      <SaveButton onClick={downloadPDF}>Download CV</SaveButton>
    </>
  );

  const deleteUserDialogFooter = (
    <>
      <Button
        className="p-button-text" icon="pi pi-times" label="No"
        onClick={() => setOpenDeleteModal(false)}
      />
      <Button
        className="p-button-text" icon="pi pi-check" label="Yes"
        onClick={deleteUser}
      />
    </>
  );
  const restoreUserDialogFooter = (
    <>
      <Button
        className="p-button-text" icon="pi pi-times" label="No"
        onClick={() => setOpenRestoreModal(false)}
      />
      <Button
        className="p-button-text" icon="pi pi-check" label="Yes"
        onClick={restoreUser}
      />
    </>
  );

  if (!user) {
    return (<div className="loading">
      <span className="loader" />
    </div>);
  }

  const findDaysLeftByEmail = email => {
    const userDaysLeft = Object.values(props.allDaysLeft).find(item => item.email === email);

    return userDaysLeft ? userDaysLeft.daysLeft : '';
  };

  const goBack = () => {
    setSearchInputValue('');
    history.push({
      pathname : ROUTE_PATH.TEAM
    });
  };

  const handleFileUpload = () => {
    if (!file) {
      return;
    }

    const formattedUserName = formatName(user.name);
    const fileNameWithoutExtension = file.name.split('.').slice(0, -1)
      .join('.');

    if (fileNameWithoutExtension !== formattedUserName) {
      setFileSelected(false);
      setFile(null);
      showUnsuccessfulToast(toast, 'File name format invalid. The title must contain the user first and last name');

      return;
    }

    const formData = new FormData();

    formData.append('file', file);

    const apiUrl = ENV_URL.API_ENDPOINT;

    fetch(`${apiUrl}/user/upload-file`, {
      method  : 'POST',
      headers : {
        Authorization : `Bearer ${props.accessToken}`
      },
      body : formData
    })
      .then(response => {
        if (response.ok) {
          setFileSelected(false);
          setFile(null);
          setHasFile(true);
          props.getAllUsers();
          showSuccessfulToast(toast, 'File uploaded successfully');
        } else {
          throw new Error('Error uploading file');
        }
      });
  };

  const handleFileChange = event => {
    const formattedUserName = formatName(user.name);
    const fileNameWithoutExtension = event.target.files[0].name.split('.').slice(0, -1)
      .join('.');
    const fileExtension = event.target.files[0].name.split('.').pop();

    if (fileExtension !== 'ovpn') {
      setFileSelected(false);
      setFile(null);
      showUnsuccessfulToast(toast, 'File type is invalid. Accepted file type: .ovpn');

      return;
    }

    if (fileNameWithoutExtension !== formattedUserName) {
      setFileSelected(false);
      setFile(null);
      showUnsuccessfulToast(toast, 'File name format is invalid. The filename must contain the user first and last name.');

      return;
    }


    if (event.target.files[0]) {
      setFile(event.target.files[0]);
      setFileSelected(true);
    } else {
      setFileSelected(false);
      setFile(null);
    }
  };

  return (
    <div className="profile-page">
      <Toast ref={toast} />
      {user &&
      <div className="profile-page-container">
        <div className="sidemenu-container">
          <SideMenu />
        </div>
        <div className="profile-page__content">
          <div className="profile-page__content-actions">
            <div className="flex-1">
              <GoBackButton
                disableRipple onClick={goBack} startIcon={<ArrowBackIcon />}
              />
              <p>Profile</p>
            </div>
            <ExportButton
              className={userCan(props.capabilities, CAPABILITIES.GENERAL_CAPABILITIES.EXPORT_USERS.key) ? 'export-btn' : 'hidden'}
              onClick={() => exportToExcel(user, user, `${user.name} information.xlsx`)} startIcon={<ExportIcon />}
            >Export to excel
            </ExportButton>
            <ExportPDFButton
              className={userCan(props.capabilities, CAPABILITIES.GENERAL_CAPABILITIES.DOWNLOAD_CV.key) ? 'export-btn' : 'hidden'}
              onClick={() => setShowPreview(true)} startIcon={<ExportPDFIcon />}
            >Export CV</ExportPDFButton>
            <Dialog
              className="cv-modal" footer={cvDialogFooter}
              header="CV Preview"
              modal
              onHide={() => setShowPreview(false)} visible={showPreview}
            >
              <CVDocument imageUrl={imageUrl} user={user} />
            </Dialog>
          </div>

          <div className="line" />

          <div className="profile-page__content-data">
            <div className="profile-image">
              <ProfilePicture isLoggedUserProfile={isLoggedUserProfile} updateUserImage={props.updateUserImage} user={user} />
            </div>

            <div className="profile-sections">
              <div className="profile-sections-top">
                <div className="profile-sections-top-name">
                  <div className="profile-name-container">
                    <p>{user.name}</p>
                    {user.role && user.role.name === 'Team lead' &&
                    <TeamLeaderIcon className="profile-svg" />
                    }
                  </div>
                  <div className="profile-picture-small-res">
                    <ProfilePicture isLoggedUserProfile={isLoggedUserProfile} updateUserImage={props.updateUserImage} user={user} />
                  </div>
                </div>
                <div className="action-btn">
                  {user?.status === 'active' &&
                  <DeleteRestoreButton
                    className={userCan(props.capabilities, CAPABILITIES.GENERAL_CAPABILITIES.REMOVE_USERS.key) ? 'delete-btn' : 'hidden'}
                    disableRipple
                    onClick={handleOnDeleteUser} size="large" startIcon={<DeleteIcon />}
                  />
                  }
                  {user?.status === 'deleted' &&
                  <DeleteRestoreButton
                    className={userCan(props.capabilities, CAPABILITIES.GENERAL_CAPABILITIES.REMOVE_USERS.key) ? 'delete-btn' : 'hidden'}
                    disableRipple
                    onClick={handleOnRestoreUser} size="large" startIcon={<RestoreIcon />}
                  />
                  }
                </div>
              </div>

              <div className="profile-sections-basic-user-data">
                <div className="header">
                  <p>User Information</p>
                  {(isLoggedUserProfile ||
  canTeamLeadAction ||
  userCan(props.capabilities, CAPABILITIES.USER_INFO_CAPABILITIES.EDIT_ALL_FIELDS.key) && !canTeamLeadAction) &&
  <EditButton className="edit-btn" onClick={() => editUser(user)} startIcon={<EditIcon />}>Edit</EditButton>
                  }
                </div>

                <div className="line" />

                <div className="data-grid">
                  <div className="grid-item">
                    <label className="key">Name</label>
                    <p className="value" disabled>{user.name}</p>
                  </div>
                  <div className="grid-item">
                    <label className="key">Role</label>
                    <p className="value" disabled>{user.job.name}</p>
                  </div>
                  <div className="grid-item">
                    <label className="key">Team</label>
                    <p className="value" disabled>{map(Object.values(user.team), team => team.name).join(', ')}</p>
                  </div>
                  <div className="grid-item">
                    <label className="key">Email</label>
                    <p className="value" disabled>{user.email}</p>
                  </div>
                  <div className="grid-item">
                    <label className="key">Phone no.</label>
                    <a className="phone-link" href={`tel:${user.phone}`}>
                      <p className="value" disabled>{user.phone}</p>
                    </a>
                  </div>
                  <div className="grid-item">
                    <label className="key">Direct Manager</label>
                    <p className="value" disabled>{user.teamlead.name || 'Not set'}</p>
                  </div>
                  {(canTeamLeadAction || userCan(props.capabilities, CAPABILITIES.USER_INFO_CAPABILITIES.VIEW_ALL_USER_INFO.key)) &&
                  <>
                    <div className="grid-item">
                      <label className="key">Previous team</label>
                      <p className="value" disabled>{user?.previousTeam.length === 0 ? 'No previous team' : map(Object.values(user?.previousTeam), team => team.name)}</p>
                    </div>
                    <div className="grid-item">
                      <label className="key">Access</label>
                      <p className="value" disabled>{user.role.name}</p>
                    </div>
                  </>
                  }
                </div>
              </div>
              {(isLoggedUserProfile || userCan(props.capabilities, CAPABILITIES.SIDEBAR_CAPABILITIES.VIEW_ADMIN.key)) &&
              <div className="file-section" style={{marginTop : '30px'}}>
                <div style={{display        : 'flex',
                  width          : '100%',
                  justifyContent : 'space-between',
                  alignItems     : 'center'}}
                >
                  <p>FILES</p>
                  {userCan(props.capabilities, CAPABILITIES.SIDEBAR_CAPABILITIES.VIEW_ADMIN.key) &&
                  <div style={{display : 'flex'}}>
                    {!hasFile &&
                    <>
                      <input
                        id="file-input" onChange={handleFileChange} style={{display : 'none'}}
                        type="file"
                      />
                      <label className="cursor-pointer" htmlFor="file-input">
                        <span
                          className="pi pi-file" style={{
                            display    : 'flex',
                            alignItems : 'center'
                          }}
                        >
                          <span style={{
                            fontSize    : '14px',
                            paddingLeft : '6px'
                          }}
                          >Upload</span>
                        </span>
                      </label>
                    </>
                    }
                    {fileSelected &&
                    <span
                      className="cursor-pointer pi pi-check" onClick={handleFileUpload} style={{
                        display     : 'flex',
                        alignItems  : 'center',
                        paddingLeft : '15px'}}
                    >
                      <span style={{fontSize    : '14px',
                        paddingLeft : '6px'}}
                      >Save</span></span>
                    }
                  </div>
                  }
                </div>
                <div className="file-section__container">
                  <div className="row">
                    <label className="key">Name</label>
                    <p className="value">OpenVPN</p>
                    <label className="key">File</label>
                    {hasFile ? <>
                      <p className="value">{formatName(user.name)}.ovpn</p>
                      <span
                        className="pi pi-download" onClick={() => downloadFile(formatName(user.name))} style={{paddingRight : '30px'}}
                      />
                      {userCan(props.capabilities, CAPABILITIES.SIDEBAR_CAPABILITIES.VIEW_ADMIN.key) &&
                      <span
                        className="pi pi-trash" onClick={() => deleteFile(formatName(user.name))} style={{paddingRight : '30px'}}
                      />
                      }

                    </> : <p>-</p>
                    }
                  </div>
                </div>
              </div>
              }

              <div className="profile-sections-extra-user-data">
                <div className="extras-header">
                  <p>EXTRA DETAILS</p>
                  {(isLoggedUserProfile ||
  canExtraDetailsAction && !canTeamLeadAction ||
  canTeamLeadAction) &&
  <EditButton className="edit-btn" onClick={openExtraFields} startIcon={<EditIcon />}>Edit</EditButton>
                  }
                </div>
                <div className="extras-content">
                  <TabView className="extra-details-tabview">
                    <TabPanel header="Bistone Information">
                      <div className="bitstone-info-tab">
                        <div className="data" >
                          <div className="data-item">
                            <label className="key">Join Date</label>
                            <InputText className="value" disabled value={formatDate(new Date(user?.joinDate))} />
                          </div>
                          {(userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.VIEW_BITSTONE_INFORMATION.key) || isLoggedUserProfile || canTeamLeadAction) &&
                          <div className="data-item">
                            <label className="key">Vacation days</label>
                            <InputText className="value" disabled value={findDaysLeftByEmail(user?.email) || 'No data'} />
                          </div>
                          }
                          {user?.extras && (userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.VIEW_BITSTONE_INFORMATION.key) || isLoggedUserProfile || canTeamLeadAction) &&
                            <div>
                              <div className={user.extras.bitstoneInfo.benefits ? 'data-item' : 'hidden'}>
                                <label className="key">Benefits</label>
                                <textarea className="textarea" disabled value={user?.extras?.bitstoneInfo?.benefits?.join(', ') || 'No benefits yet'} />
                              </div>
                              <div className="data-item">
                                <label className="key">Team buildings</label>
                                <InputText className="value" disabled value={(user?.extras?.bitstoneInfo?.teamBuildings || 0) + ' RON'} />
                              </div>
                              <div className="data-item">
                                <label className="key">Learning and development</label>
                                <InputText className="value" disabled value={(user?.extras?.bitstoneInfo?.learningAndDev || 0) + ' EUR'} />
                              </div>
                            </div>
                          }
                          {user?.extras && (userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_BITSTONE_INFORMATION.key) || canTeamLeadAction) &&
                            <div>
                              <div className={user.extras?.bitstoneInfo?.currentRole ? 'data-item' : 'hidden'}>
                                <label className="key">Current role</label>
                                <textarea
                                  className="value-textarea" disabled
                                  value={user.extras?.bitstoneInfo?.currentRole}
                                />
                              </div>
                              <div className={user.extras?.bitstoneInfo?.about ? 'data-item' : 'hidden'}>
                                <label className="key">About</label>
                                <textarea
                                  className="value-textarea" disabled
                                  value={user.extras?.bitstoneInfo?.about}
                                />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    </TabPanel>
                    {userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_RELEVANT_EXPERIENCE.key) &&
                    <TabPanel header="Relevant Experience">
                      {user?.extras &&
                        <div className="relevant-info-tab">
                          {Object.keys(user.extras?.relevantExperience || {}).map((key, index) => {
                            const experience = user.extras.relevantExperience[key];

                            const startDate = formatExperienceDate(experience.startDate);
                            const endDate = formatExperienceDate(experience.endDate);

                            const nextExperience = user.extras?.relevantExperience?.[Object.keys(user.extras.relevantExperience || {})[index + 1]];
                            const nextStartDate = nextExperience ? formatExperienceDate(nextExperience.startDate) : null;

                            return (
                              <div className="experience-container" key={experience.company}>
                                <div className="experience-content">
                                  <div className="left-side">
                                    <p className="title">{experience.position}</p>
                                    <p className="sub-title">{formatDateRelevantExpUI(startDate)} - {formatDateRelevantExpUI(endDate)}</p>
                                    <p className="sub-title">{experience.company}</p>
                                  </div>
                                  <div className="right-side">
                                    <p className="text">{experience.description}</p>
                                  </div>
                                </div>
                                {nextStartDate && shouldDisplayLine(endDate, nextStartDate) && <div className="line" />}
                              </div>
                            );
                          })}
                        </div>
                      }
                      {(!user.extras?.relevantExperience || user.extras?.relevantExperience.length === 0) &&
                        <div className="noInfo">No info added yet.</div>
                      }
                    </TabPanel>
                    }

                    <TabPanel
                      header="Project Experience"
                    >
                      {user?.extras?.projectsInfo &&
                        <div className="projects-container">
                          {Object.values(user.extras.projectsInfo).map(project =>
                            <div className="each-project" key={project.title}>
                              <div className="project">
                                <div className="left-side">
                                  <p className="title">{project.title}</p>
                                </div>
                                <div className="right-side">
                                  <p className={(userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_PROJECTS_EXPERIENCE.key) || isLoggedUserProfile || canTeamLeadAction) && project.mainGoal ? 'text' : 'hidden'}><span>Main goal:</span>{project.mainGoal}</p>
                                  <p className={project.responsabilities ? 'text' : 'hidden'}><span>Responsabilities:</span>{project.responsabilities}</p>
                                  <p className={(userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_PROJECTS_EXPERIENCE.key) || isLoggedUserProfile || canTeamLeadAction) && project.technicalSkills ? 'text' : 'hidden'}><span>Technical skills (front-end):</span>{project.technicalSkills}</p>
                                </div>
                              </div>
                              <div className="line" />
                            </div>
                          )}
                        </div>
                      }
                      {(!user.extras?.projectsInfo || user.extras?.projectsInfo.length === 0) &&
                        <div className="noInfo">No info added yet.</div>
                      }
                    </TabPanel>

                    <TabPanel header="Personal Information">
                      {user.extras?.personalInfo &&
                        <div className="personal-info-tab">
                          <div className="data">
                            {userCan(props.capabilities, CAPABILITIES.SIDEBAR_CAPABILITIES.VIEW_ADMIN.key) &&
                            <>
                              <div className={user.extras?.personalInfo?.officialName ? 'data-item' : 'hidden'}>
                                <label className="key">Official name</label>
                                <InputText className="value" disabled value={user?.extras?.personalInfo?.officialName} />
                              </div>
                              <div className={user.extras?.personalInfo?.birthdate ? 'data-item' : 'hidden'}>
                                <label className="key">Birthdate</label>
                                <InputText className="value" disabled value={formatDate(new Date(user?.extras?.personalInfo?.birthdate))} />
                              </div>
                              <div className={user.extras?.personalInfo?.currentAddress ? 'data-item' : 'hidden'}>
                                <label className="key">Address</label>
                                <InputText className="value" disabled value={user.extras.personalInfo?.currentAddress} />
                              </div>
                              <div className={user.extras?.personalInfo?.personalEmailAddress ? 'data-item' : 'hidden'}>
                                <label className="key">Personal email address</label>
                                <InputText className="value" disabled value={user.extras.personalInfo?.personalEmailAddress} />
                              </div>
                            </>
                            }
                            <div className={user.extras?.personalInfo?.hometown ? 'data-item' : 'hidden'}>
                              <label className="key">Hometown</label>
                              <InputText className="value" disabled value={user.extras.personalInfo?.hometown} />
                            </div>

                            <div className={user.extras?.personalInfo?.education.length > 0 ? 'data-item' : 'hidden'}>
                              <label className="key">Education</label>
                              <p className="textarea nl">{user.extras?.personalInfo?.education.join('\n')}</p>
                            </div>
                            {user.extras?.personalInfo?.skills && (userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_PERSONAL_INFO.key) || isLoggedUserProfile || canTeamLeadAction) &&
                            <div className={Object.values(user.extras?.personalInfo?.skills)?.length > 0 ? 'data-item' : 'hidden'}>
                              <label className="key">Skills</label>
                              <p className="textarea">
                                {Object.values(user.extras?.personalInfo?.skills || {}).map(skill => skill.name)
                                  .join(', ')}
                              </p>
                            </div>
                            }
                            {(userCan(props.capabilities, CAPABILITIES.EXTRA_INFO_CAPABILITIES.EDIT_PERSONAL_INFO.key) || isLoggedUserProfile || canTeamLeadAction) &&
                            <div className={user.extras?.personalInfo?.languages ? 'data-item' : 'hidden'}>
                              <label className="key">Languages</label>
                              <InputText
                                className="value" disabled
                                value={user.extras?.personalInfo?.languages?.join(', ')}
                              />
                            </div>
                            }
                            <div className={user.extras?.personalInfo?.hobbies?.length > 0 ? 'data-item' : 'hidden'}>
                              <label className="key">Hobbies</label>
                              <InputText className="value" disabled value={user.extras?.personalInfo?.hobbies} />
                            </div>
                            <div className={user.extras?.personalInfo?.isVegan ? 'data-item' : 'hidden'}>
                              <label className="key">Dietary restrictions</label>
                              <p className="value-p">{user.extras?.personalInfo?.isVegan}</p>
                            </div>
                          </div>
                        </div>
                      }
                      {!user.extras?.personalInfo &&
                        <div className="noInfo">No info added yet.</div>
                      }
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </div>
          </div>
        </div>

        {user &&
          <UserModal
            canTeamLeadAction={canTeamLeadAction}
            setSubmitted={setSubmitted}
            setUserDialog={setUserDialog}
            submitted={submitted}
            userDialog={userDialog}
            userProps={userProps}
          />
        }

        {displayExtraFields &&
          <ExtraFieldsComponent
            canTeamLead={canTeamLeadAction}
            displayExtraFields={displayExtraFields}
            isLoggedUserProfile={isLoggedUserProfile}
            setDisplayExtraFields={setDisplayExtraFields} setSubmitted={setSubmitted}
            setUserInfo={setUser}
            submitted={submitted}
            userInfo={user}
          />
        }

        <Dialog
          className="deleteModal" footer={deleteUserDialogFooter} header="Confirm"
          modal
          onHide={() => setOpenDeleteModal(false)} style={{width : '450px'}} visible={openDeleteModal}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
            {user && <span>Are you sure you want to delete <b>{user.name}</b>?</span>}
          </div>
        </Dialog>
        <Dialog
          className="deleteModal" footer={restoreUserDialogFooter} header="Confirm"
          modal
          onHide={() => setOpenRestoreModal(false)} style={{width : '450px'}} visible={openRestoreModal}
        >
          <div className="confirmation-content">
            <i className="pi pi-exclamation-triangle mr-3" style={{fontSize : '2rem'}} />
            {user && <span>Are you sure you want to restore <b>{user.name}</b>?</span>}
          </div>
        </Dialog>
      </div>
      }
    </div>
  );
};

ProfilePage.displayName = 'ProfilePage';
ProfilePage.propTypes = {
  roles                     : object.isRequired,
  skills                    : object.isRequired,
  users                     : object.isRequired,
  jobs                      : object.isRequired,
  teams                     : object.isRequired,
  capabilities              : oneOfType([array, object]).isRequired,
  getLoggedUser             : object.isRequired,
  getRoles                  : func.isRequired,
  getProjectsTitle          : func.isRequired,
  getJobs                   : func.isRequired,
  getTeams                  : func.isRequired,
  deleteUser                : func.isRequired,
  restoreUser               : func.isRequired,
  updateUserImage           : func.isRequired,
  getAllUsers               : func.isRequired,
  editUserSuccessful        : bool.isRequired,
  updateUserImageSuccessful : bool.isRequired,
  restoreUserSuccessful     : bool.isRequired,
  deleteUserSuccessful      : bool.isRequired,
  allDaysLeft               : object.isRequired,
  getAllDaysLeft            : func.isRequired,
  getSkills                 : func.isRequired,
  teamLeads                 : oneOfType([array, object]).isRequired,
  getTeamleads              : func.isRequired,
  downloadFile              : func.isRequired,
  accessToken               : string.isRequired
};

export default ProfilePage;
